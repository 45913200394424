<template>
    <div class="container">
        <div class="export">
            <div class="input">
                <div>关键词：</div>
                <el-input v-model="barcode" clearable placeholder="商品条码"></el-input>
            </div>
            <el-button type="primary" @click="getStockList('nopage')">查询</el-button>
            <!-- <el-button type="info" @click="stockExport">导出</el-button> -->
        </div>
        <div class="table">
            <el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }" border
                :data="stockList" @selection-change="handleSelectionChange" style="width: 100%">
                <el-table-column type="selection" width="60"></el-table-column>
                <el-table-column type="index" label="编号" width="60"></el-table-column>
                <el-table-column prop="product_name" label="商品名称"></el-table-column>
                <el-table-column prop="barcode" label="商品条码"></el-table-column>
                <el-table-column prop="spec_name" width="150" label="规格"></el-table-column>
                <el-table-column prop="prelock" width="150" label="锁库数量"></el-table-column>
                <el-table-column prop="status" width="150" label="状态"></el-table-column>
                <el-table-column prop="expire_date" sortable label="有效期"></el-table-column>
                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button @click="prelockstockAdd(scope.row)" type="text" size="small">延期</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination">
            <el-pagination background :hide-on-single-page="false" @current-change="curretnStockChange"
                @prev-click="curretnStockChange" @size-change="handleSizeChange" @next-click="curretnStockChange"
                :current-page="stockPage" :page-sizes="[10, 20, 30, 40]" layout="total,sizes,prev, pager, next,jumper"
                :total="stockTotal"></el-pagination>
        </div>
        <el-dialog title="锁定库存延期" :show-close="true" @close="diaClose" :close-on-press-escape="false"
            :close-on-click-modal="false" :visible.sync="dialogVisible" width="40%">
            <el-form :model="formInline" class="demo-form-inline">
                <el-form-item label="商品条码">
                    <el-input v-model="formInline.barcode" placeholder="商品条码"></el-input>
                </el-form-item>
                <el-form-item label="预锁数量">
                    <el-input v-model="formInline.prelock" placeholder="预锁数量"></el-input>
                </el-form-item>
                <el-form-item label="预锁天数">
                    <el-radio-group v-model="formInline.expire">
                        <el-radio :label="5">五天</el-radio>
                        <el-radio :label="10">十天</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-header">
                <el-button @click="stockBook" type="primary">锁定库存</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>

import { formatDateTime, urlEncode } from '@/utils/util.js'
import { BASE_URL, BASE_URL2 } from '../request/request.config';
export default {
    name: 'stock',
    data() {
        return {
            dialogVisible: false,
            stockInput: '',
            limit: 10,
            BASE_URL2,
            stockPage: 1,
            stockTotal: 0,
            barcode: '',
            currentStockDetail: {},
            formInline: {
                barcode: '',
                prelock: 1,
                expire: 5,
            },
            stockList: [],
            multipleSelection: [],
        }
    },
    computed: {
        shopInfo() {
            return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
        }
    },
    created() {
        this.getStockList()
    },
    watch: {

    },
    methods: {
        diaClose() {
            this.formInline = {
                barcode: '',
                prelock: 1,
                expire: 5,
            }
        },
        handleSelectionChange(val) {
            // 多选值改变
            this.multipleSelection = val
        },
        prelockstockAdd(data) {
            // 库存延期
            this.dialogVisible = true
            this.formInline.barcode = data.barcode
        },
        stockBook() {
            // 库存延期
            var data = this.formInline
            this.$http2
                .post('/merapi/prelockstock/add', data)
                .then(res => {
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        this.dialogVisible = false
                        this.getStockList()
                    } else {
                        this.$message.error(res.data.msg)
                    }

                })
                .catch(err => {
                    return this.$message.error(err)
                })

        },
        curretnStockChange(e) {
            // 切换page
            this.stockPage = e
            this.getStockList()
        },
        handleSizeChange(e) {
            this.limit = e
            this.getStockList()
        },

        stockExport() {
            // 库存列表导出

            var barcode = this.barcode
            var multipleSelection = this.multipleSelection
            // eslint-disable-next-line camelcase
            var ids = []
            if (multipleSelection.length > 0) {
                multipleSelection.forEach(ele => {
                    ids.push(ele.stock_id)
                })
            }
            // eslint-disable-next-line camelcase
            ids = ids.join(',')
            var token = window.localStorage.getItem('token')
            try {
                let params = {
                    ids, barcode, token

                }
                let data = urlEncode(params)
                window.location.href = BASE_URL2 + '/merapi/stock/export?' + data
            } catch {
                this.$message.error('服务器繁忙，请稍后重试')
            }
        },
        getStockList(e) {
            // 获取库存列表

            var barcode = this.barcode

            this.stockPage = e === 'nopage' ? 1 : this.stockPage
            var page = this.stockPage
            var limit = this.limit
            this.$http2
                .get('/merapi/prelockstock/list', { params: { barcode, page, limit } })
                .then(res => {
                    this.stockList = res.data.data.rows
                    this.stockTotal = res.data.data.total
                })
                .catch(err => {
                    return this.$message.error(err)
                })
        }
    }
}
</script>
  
<style scoped lang="less">
// .el-table/deep/.el-table__body tr:hover>td {
//   background-color: #eee!important;
// }
.red {
    color: red;
}

.el-table/deep/.el-table__body tr.bgcred>td {
    background-color: rgb(245, 108, 108) !important;
}

.brand {
    margin-left: 30px;
}

.export {
    display: flex;
    align-items: center;

    .date {
        font-size: 18px;
        margin-right: 20px;
    }

    .input {
        display: flex;
        align-items: center;

        div {
            width: 100px;
            font-size: 18px;
        }

        .el-input {
            width: 300px;
            font-size: 14px;
        }
    }

    .el-button {
        margin-left: 40px;
    }

    .msg {
        margin-left: 30px;
        font-size: 13px;
        color: rgb(102, 177, 255);
    }
}

.el-table /deep/ .bgcred {
    background-color: rgb(245, 108, 108);
    color: #fff;

}

.table {
    margin-top: 30px;
}

.logistics {
    margin-left: 20px;
    font-size: 18px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: center;
}
</style>
  